<template>
  <el-menu
        :default-active="active?active:$router.currentRoute.path"
        class="el-menu-vertical-demo"
		:class="$store.state.closeleftmenu?'miniWitdh':''"
        @open="handleOpen"
        @close="handleClose"
		@select="selectmenu"
		:collapse="$store.state.closeleftmenu"
		:unique-opened="true"
		background-color="#fff"
		text-color="#625f6e" 
		active-text-color="#409EFF">
		<div v-for="(item,index) in menu" :key="index">
			<el-menu-item v-if="item.menutype=='2'" :index="item.url"  @click="$router.push(item.url)">
			    <i :class="item.icon"></i>
			    <span slot="title">{{item.title}}</span>
			</el-menu-item>
			<el-submenu v-if="item.menutype!='2'" :index="String(index+1)">
				<template slot="title">
					<i :class="item.icon" style="color: rgb(165,172,179);"></i>
					<span>{{item.title}}</span>
				</template>		  
				
				<div v-for="(item2,index2) in item.children" :key="index2">
					<el-menu-item-group v-if="item2.menutype=='2'">
						<el-menu-item :index="item2.url" @click="$router.push(item2.url)">{{item2.title}}</el-menu-item>
					</el-menu-item-group>
					
					<el-submenu v-if="item2.menutype!='2'" :index="String(index+'1'+index2)">
						<template slot="title">
							<span>{{item2.title}}</span>
						</template>							
						<el-menu-item v-for="(item3,index3) in item2.children" :index="item3.url" @click="$router.push(item3.url)">{{item3.title}}</el-menu-item>
					</el-submenu>
				</div>
				
			</el-submenu>
		</div>
		
		
      </el-menu>
</template>

<script>
 import {mapState} from 'vuex'
 import routerdata from '../router/data.js'
 export default {
	 data(){
		 return{
			 active:'',
			 menu:[]
		 }
	 },
	 created() {
		 this.menu = this.$store.state.userinfo.menu
		 // this.menu = routerdata
		 console.log(this.menu)
	 },
    methods: {
	  selectmenu(path,list){
		 
	  },
      handleOpen(key, keyPath) {
        console.log(key, keyPath);
      },
      handleClose(key, keyPath) {
        console.log(key, keyPath);
      }
    },
	watch:{
		history(newval,oldval){
			this.active = newval.path	
		}
	},
	computed:{
		...mapState(['history'])
	}
  }
</script>

<style lang="scss" scoped>
.menu_left {
    width: 200px;
    height: 100%;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    z-index: 6;
}

.myheader_left {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 20px;
    box-sizing: border-box;
}

.el-menu-vertical-demo {
    width: 200px !important;
    height: 100%;
    overflow-y: auto;
    transition: all 0.5s;
	border-right: 1px solid #eee !important;
}

.el-menu-vertical-demo::-webkit-scrollbar {
    width: 0 !important
}

.el-menu-vertical-demo {
    overflow: -moz-scrollbars-none;
    border: none;
}

.miniWitdh {
    width: 70px !important;
    transition: all 0.5s;
}

::v-deep .el-menu-item-group__title {
    display: none;
}

::v-deep .el-submenu__title i {
    color: rgb(165, 172, 179);
}

::v-deep .el-submenu__title {
    color: rgb(165, 172, 179);
}


::v-deep .el-submenu {

    .el-menu-item {
        position: relative;
        // background-color: #161d31 !important;
        // padding-left: 55px !important;

        &:hover {
            background-color: #f6f6f6 !important;

            &::after {
                opacity: 1;
            }
        }

        &:after {
            content: '';
            position: absolute;
            right: 0;
            width: 5px;
            background-color: #409EFF !important;
            height: 100%;
            opacity: 0;
        }
    }

    .is-active {
        position: relative;
        background-color: rgba(36, 153, 239, .06) !important;

        &::after {
            opacity: 1;
        }
    }
}

::v-deep .el-submenu__icon-arrow {
    color: rgb(165, 172, 179) !important;
}

.clearboth:after {
    width: 0 !important;
}

::v-deep .is-active .clearcolor .el-submenu__title {
    color: #fff;
}

::v-deep .is-active .is-active .el-submenu__title {
    color: #fff;
}
</style>